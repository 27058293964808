<template>
  <main class="sub __agree-wrap">
    <div class="inner __join-wrap">
      <div class="tit req-type">
        <h1>개인정보 처리방침</h1>
        <p class="stit"></p>
      </div>
      <div class="sub-cont">
        <div class="terms_wrap" v-html="privacyTerms"></div>
      </div>
    </div>
  </main>
</template>

<script>
import { privacy } from 'Configs/terms'

export default {
  data() {
    return {
      privacyTerms: Object.freeze(privacy),
    }
  },
}
</script>

<style></style>
